import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Use a divider for seperating sections of content.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=587%3A0" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "style",
      "style": {
        "position": "relative"
      }
    }}>{`Style`}</h2>
    <p>{`Currently all dividers have a solid style. This can be changed according to a
specific need of a brand.`}</p>
    <h2 {...{
      "id": "color",
      "style": {
        "position": "relative"
      }
    }}>{`Color`}</h2>
    <p>{`Divider defaults to Chameleon's `}<inlineCode parentName="p">{`grey-30`}</inlineCode>{` color. Of course, depending on the use
case, you can swap the color with another one from Chameleon's
`}<a parentName="p" {...{
        "href": "/foundations/color"
      }}>{`color palette`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      